"use client";

import Link from "next/link";

import { SecondaryButton } from "@causevest/ui-kit";
import { Container, Stack, Typography } from "@mui/material";

import classes from "@lib/styles/common.module.scss";
import { AppRoutes } from "@lib/types";

interface ReturnType {
  error: Error & { digest?: string };
  reset: () => void;
}

export default function Error({ reset, error }: ReturnType) {
  return (
    <Container sx={{ display: "flex" }} className={classes.pageWrapper}>
      <Stack sx={{ m: "auto", gap: "20px" }} alignItems="center">
        <Typography variant="h2" sx={{ fontSize: "26px", fontWeight: 400 }}>
          {/*{process.env.NODE_ENV === "production" ? "Something went wrong" : error.message}*/}
          {error.message}
        </Typography>
        <Stack flexDirection="row" alignItems="center" flexWrap="wrap" gap="15px">
          <SecondaryButton
            variant="outlined"
            onClick={reset}
            sx={{ width: "135px", height: "35px" }}
          >
            Try again
          </SecondaryButton>
          <Typography sx={{ fontSize: "16px" }}>or</Typography>
          <Link href={AppRoutes.MAIN}>
            <SecondaryButton variant="outlined" sx={{ p: "10px", height: "35px" }}>
              Follow to the Main page
            </SecondaryButton>
          </Link>
        </Stack>
      </Stack>
    </Container>
  );
}
